import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88')
];

export const server_loads = [0,6,7,14,2,4,20,22,23,3];

export const dictionary = {
		"/(landings)/(home)": [~86],
		"/(iam)/auth/recover": [~80,[25]],
		"/(iam)/auth/signin": [~81,[25]],
		"/(iam)/auth/signup": [~82,[25]],
		"/(hub)/console": [36,[6]],
		"/(hub)/console/cms/[resource_id]/richtext": [37,[7]],
		"/(hub)/console/cms/[resource_id]/text": [~38,[8]],
		"/(hub)/console/codes": [~39,[6]],
		"/(hub)/console/codes/[id]": [~40,[9]],
		"/(hub)/console/crm/feedback": [~41,[6]],
		"/(hub)/console/crm/partnership": [~42,[6]],
		"/(hub)/console/crm/subscription": [~43,[6]],
		"/(hub)/console/feed": [~44,[6]],
		"/(hub)/console/feed/[post_id]/edit": [~45,[6]],
		"/(hub)/console/groups": [~46,[6]],
		"/(hub)/console/groups/[id]": [~47,[10]],
		"/(hub)/console/groups/[id]/roles": [~48,[10]],
		"/(hub)/console/groups/[id]/terms": [~49,[10]],
		"/(hub)/console/organizations": [~50,[6]],
		"/(hub)/console/organizations/[id]": [~51,[11]],
		"/(hub)/console/organizations/[id]/groups": [~52,[11]],
		"/(hub)/console/organizations/[id]/groups/[group_id]": [~53,[12]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/roles": [~54,[12]],
		"/(hub)/console/organizations/[id]/groups/[group_id]/terms": [~55,[12]],
		"/(hub)/console/organizations/[id]/roles": [~56,[11]],
		"/(hub)/console/terms": [~57,[6]],
		"/(hub)/console/terms/[id]": [~58,[13]],
		"/(hub)/console/terms/[id]/blueprints": [~59,[13]],
		"/(hub)/console/terms/[id]/changes": [~60,[13]],
		"/(hub)/console/users": [~61,[6]],
		"/(hub)/dev": [62,[14],[15]],
		"/(hub)/dev/examples": [~63,[16]],
		"/(hub)/dev/examples/await": [64,[16]],
		"/(hub)/dev/playground": [~65,[17]],
		"/(hub)/dev/playground/buttons": [66,[17]],
		"/(hub)/dev/playground/dates": [67,[17]],
		"/(hub)/dev/playground/inputs": [~68,[17]],
		"/(hub)/dev/playground/modal": [69,[17]],
		"/(hub)/dev/playground/popover": [70,[17]],
		"/(hub)/dev/playground/switch": [71,[17]],
		"/(hub)/dev/playground/tabs": [72,[17]],
		"/(hub)/dev/playground/toasts": [73,[17]],
		"/(landings)/faststart": [87],
		"/(education)/feed": [~30,[2]],
		"/(education)/groups": [~31,[2]],
		"/(education)/groups/[id]": [~32,[4]],
		"/(education)/groups/[id]/terms": [~33,[4]],
		"/(hub)/lms": [74,[18],[19]],
		"/(hub)/lms/[type=blueprint_type]": [~75,[18],[19]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/content": [77,[20,22,23],[21]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]/materials": [78,[20,22],[21]],
		"/(hub)/lms/[type=blueprint_type]/[root_id]/[[nested_id]]": [~76,[20,22],[21]],
		"/(hub)/mentorship": [79,[24]],
		"/qualify/[...path]": [88],
		"/(education)/rewards": [~34,[2]],
		"/(education)/rewards/[reward_id]": [~35,[2],[,5]],
		"/(iam)/user": [~83,[26]],
		"/(iam)/user/account": [~84,[26]],
		"/(iam)/user/security": [~85,[26]],
		"/(education)/[type=term_type]": [~27,[2]],
		"/(education)/[type=term_type]/[slug]": [~28,[3]],
		"/(education)/[type=term_type]/[slug]/[blueprint_id]": [~29,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';