import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

const isDev = process.env.NODE_ENV === 'development';

if (!isDev) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://b03dfbbdab0041b5516a811f8eae58f9@o4507351359619072.ingest.de.sentry.io/4507351410344016',
    tracesSampleRate: 1.0,
  });
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
